<template>
  <v-card class="elevation-0">
    <v-tabs centered>
        <v-tab background-color="transparent" color="basil" grow>
            Estado de Cuenta
        </v-tab>
        <v-tab-item>
            <v-row class="ma-0 mt-6">
                <v-spacer/>
                <v-menu offset-y :close-on-content-click="closeDatePicker(dates)">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field rounded outlined dense v-model="dates" label="Rango de Fecha" prepend-inner-icon="mdi-calendar" v-bind="attrs" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="dates" range></v-date-picker>
                </v-menu>
                <v-btn @click="dialog=true, toSend='edoCta'" rounded color="primary" class="ml-2 elevation-0">Enviar</v-btn>
                <v-spacer/>
            </v-row>
            <!--iframe :src="liga+'company/send_due_balance_report?company_id='+company+'filter[date_between]='+dates" style="height:50vh;" width="100%"></iframe-->
            <div v-html="undefined"></div>
        </v-tab-item>
        <v-tab background-color="transparent" color="basil" grow>
            Saldos Vencidos
        </v-tab>
        <v-tab-item>
            <v-row class="ma-0 my-6">
                <v-spacer/>
                <v-btn @click="dialog=true, toSend='salVen'" rounded color="primary" class="ml-2 elevation-0">Enviar</v-btn>
                <v-spacer/>
            </v-row>
            <!--iframe :src="liga+'company/send_due_balance_report?company_id='+company" style="height:50vh;" width="100%"></iframe-->
            <div v-html="htmlContent"></div>
        </v-tab-item>
    </v-tabs>
    <v-dialog v-model="dialog" width="600px">
        <v-card>
            <v-row class="ma-0 pb-4 pt-6">
                <v-spacer/>
                ¿A que correo quieres mandar esta cotización?
                <v-spacer/>
            </v-row>
            <v-autocomplete class="mb-4 mt-2" clearable item-text="email" v-model="email" :items="emailLists" item-value="email" label="Email" style="max-width:500px!important; margin:auto;">
                <template slot="no-data">
                    <div class="px-4 py-1">
                        No existen correos relacionados. 
                        <v-text-field v-model="otroMail" type="email" label="Otro"></v-text-field>
                    </div>
                </template>  
                <template v-slot:item="{item, attrs, on}">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{item.email}} <v-chip small class="ml-2"  style="font-size:8px;">{{item.job_position}}</v-chip>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template> 
            </v-autocomplete>
            <v-row class="ma-0 pb-4">
                <v-spacer/>
                <v-btn text color="primary" :loading="sending" :disabled="true" @click="sendEmail()">Enviar</v-btn>
                <v-spacer/>
            </v-row>
        </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios"
export default {
    data(){
        return{
            dates:[],
            dialog:false,
            email:'',
            otroMail:'',
            toSend:undefined,
            sending:false,
            htmlContent: ''
        }
    },
    props:{
        company:Number
    },
    created(){
        var date = new Date()
        this.dates[0] = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleString("sv-SE", {timeZone: "America/Monterrey"}).toString().slice(0, 10)
        this.dates[1] = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleString("sv-SE", {timeZone: "America/Monterrey"}).toString().slice(0, 10)
    },
    mounted(){
        axios.get('https://suministros.uno/api/v2/company/send_due_balance_report?company_id=' + this.company)
        .then(response => {
            this.htmlContent = response.data;
        })
    },
    computed:{
        liga(){
            return process.env.VUE_APP_BACKEND_ROUTE
        },
        emailLists(){
            return this.$store.state.company.companies.filter(company=>company.id==this.company && company.email!=null).concat(this.$store.state.contact.contacts.filter(contact=>contact.company_id == this.company))
        }
    },
    methods:{
        closeDatePicker(dates){
            if(dates!=undefined && dates.length==2){
                return true
            }else{
                return false
            }
        },  
        sendEmail() {
            this.sending = true
            if(this.email==''||this.email==null||this.email==undefined){
                this.email = this.otroMail
            }
            if(this.toSend=='edoCta'){

            }else if(this.toSend=='salVen'){

            }
        }
    }
}
</script>

<style>

</style>